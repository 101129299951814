import { action, makeAutoObservable } from "mobx";

class AppStore {
  loginResponse: LoginResponse;
  selectedEntity: number = -1;
  showEntityDialog: boolean = false;
  userEntities: Entity[] = [];
  loading: boolean = false;
  tasksData: Task[] = [];
  socket: any;
  constructor() {
    this.loginResponse = {} as any;

    makeAutoObservable(this, {
      setSelectedEntity: action,
      setShowEntityDialog: action,
      setLoginResponse: action,
      setUserEntities: action,
    });
  }

  async loadSocket() {}
  setLoading(loading: boolean) {
    this.loading = loading;
  }
  setCurrentTasks(tasks) {
    this.tasksData = tasks;
  }
  setSelectedEntity(entity: number) {
    this.selectedEntity = entity;
  }

  setShowEntityDialog(showEntityDialog: boolean) {
    this.showEntityDialog = showEntityDialog;
  }

  setLoginResponse(response: LoginResponse) {
    this.loginResponse = response;
  }

  setUserEntities(entities: Entity[]) {
    this.userEntities = entities;
  }
}

const appStore = new AppStore();

export default appStore;
