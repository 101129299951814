import * as React from "react";
import { Box, ButtonBase, Icon, styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import {
  BadgeValue,
  BulletIcon,
  InternalLink,
  StyledText,
} from "./MatxVerticalNav";

export const MatxNavItem = ({ item, onClick, index, mode }) => {
  return (
    <InternalLink key={index}>
      <NavLink
        to={item.path}
        onClick={onClick}
        className={({ isActive }) =>
          isActive
            ? `navItemActive ${mode === "compact" && "compactNavItem"}`
            : `${mode === "compact" && "compactNavItem"}`
        }
      >
        <ButtonBase key={item.name} name="child" sx={{ width: "100%" }}>
          {item?.icon ? (
            <Icon className="icon" sx={{ width: 36 }}>
              {item.icon}
            </Icon>
          ) : (
            <React.Fragment>
              <BulletIcon
                className={`nav-bullet`}
                sx={{ display: mode === "compact" && "none" }}
              />
              <Box
                className="nav-bullet-text"
                sx={{
                  ml: "20px",
                  fontSize: "11px",
                  display: mode !== "compact" && "none",
                }}
              >
                {item.iconText}
              </Box>
            </React.Fragment>
          )}
          <StyledText mode={mode} className="sidenavHoverShow">
            {item.name}
          </StyledText>

          <Box mx="auto" />

          {item.badge && (
            <BadgeValue className="sidenavHoverShow">
              {item.badge.value}
            </BadgeValue>
          )}
        </ButtonBase>
      </NavLink>
    </InternalLink>
  );
};
