import { useState, Fragment } from "react";
import { Icon, IconButton, styled } from "@mui/material";
import { topBarHeight } from "app/utils/constant";
import { useNavigate } from "react-router-dom";
import { Themecolors } from "api/Colors";

const SearchContainer = styled("div")(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  zIndex: 9,
  width: "100%",
  display: "flex",
  alignItems: "center",
  height: topBarHeight,
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  "&::placeholder": {
    color: theme.palette.text.primary,
  },
}));

const SearchInput = styled("input")(({ theme }) => ({
  width: "100%",
  border: "none",
  outline: "none",
  fontSize: "1rem",
  paddingLeft: "20px",
  height: "calc(100% - 5px)",
  background: theme.palette.primary.main,
  color: theme.palette.text.primary,
  "&::placeholder": { color: theme.palette.text.primary },
}));

const MatxSearchBox = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [taskId, setTaskId] = useState("");

  const toggle = () => {
    setOpen(!open);
    setTaskId("");
  };
  const handlechange = (event) => {
    const NumericValue = event.target.value.replace(/\D/g, "");
    setTaskId(NumericValue);
  };

  const handleKey = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = () => {
    setOpen(false);
    navigate(`/user/task/${taskId}`);
  };

  return (
    <Fragment>
      {!open && (
        <IconButton
          onClick={toggle}
          sx={{
            "&:hover": {
              backgroundImage: Themecolors.Manu_hv1,
            },
          }}
        >
          <Icon sx={{ color: Themecolors.Icons_blue }}>search</Icon>
        </IconButton>
      )}

      {open && (
        <SearchContainer>
          <SearchInput
            type="text"
            placeholder="Task Id..."
            autoFocus
            value={taskId}
            onChange={handlechange}
            onKeyDown={handleKey}
          />

          <IconButton onClick={toggle} sx={{ mx: 2, verticalAlign: "middle" }}>
            <Icon sx={{ color: "text.primary" }}>close</Icon>
          </IconButton>
        </SearchContainer>
      )}
    </Fragment>
  );
};

export default MatxSearchBox;
