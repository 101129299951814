// MainDialog.tsx

import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface MainDialogProps {
  onClose: () => void;
  onOutsideClick: () => void;
  dialogTitle: string;
  dialogDescription: string;
  children: React.ReactNode;
  width?: string | number;
  height?: string | number;
  minWidth?: string | number;
  minHeight?: string | number;
}

const CustomDialogTitle = styled(DialogTitle)(({ theme }) => ({
  position: "relative",
  "& .MuiTypography-root": {
    // marginBottom: theme.spacing(1),
  },
}));

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(1),
  top: theme.spacing(1),
  color: theme.palette.grey[500],
}));

const MainDialog: React.FC<MainDialogProps> = ({
  onClose,
  onOutsideClick,
  dialogTitle,
  dialogDescription,
  children,
  width = "95vw",
  height = "95vh",
  minWidth = "800px",
  minHeight = "450px",
}) => {
  return (
    <Dialog
      open={true}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          onOutsideClick();
        } else {
          onClose();
        }
      }}
      maxWidth={"xl"}
      PaperProps={{
        style: {
          width: width,
          height: height,
          minWidth: minWidth,
          minHeight: minHeight,
          padding: "10px",
          maxWidth: "80vw !important",
        },
      }}
    >
      <CustomDialogTitle>
        {dialogTitle}
        <Typography variant="body1">{dialogDescription}</Typography>
        <CloseButton onClick={onClose}>
          <CloseIcon />
        </CloseButton>
      </CustomDialogTitle>
      <DialogContent dividers>{children}</DialogContent>
    </Dialog>
  );
};

export default MainDialog;
