import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import { api } from "api/API";
import appStore from "app/mobxStore/AppStore";
import CloseIcon from "@mui/icons-material/Close";
import { CloseButton } from "app/formComponents/MainDialog";
import { Box, Icon, IconButton, TextField } from "@mui/material";
import { observer } from "mobx-react";
interface Entity {
  entityId: number;
  entityName: string;
  entityLocation: string;
  entityPhone: string;
  entityDescription: string;
}

interface EntityDialogProps {
  onClose: () => void;
}

const EntityButton = styled(Button)`
  margin: 10px;
`;

const EntityDialog: React.FC<EntityDialogProps> = observer(({ onClose }) => {
  const [entities, setEntities] = useState([]);
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");
  useEffect(() => {
    setOpen(appStore.showEntityDialog);
  }, [`${appStore.showEntityDialog}`]);
  useEffect(() => {
    if (appStore.loginResponse.success) {
      api
        .post(`get-user-entities`, {
          body: {
            entityId: `${appStore.loginResponse.user[0].entities}`,
          },
        })
        .then((res) => {
          if (res.success) {
            if (res.data.length > 1 && appStore.selectedEntity === -1) {
              setOpen(false);
            } else {
              appStore.selectedEntity = res.data[0].entityId;
            }
            appStore.setUserEntities(res.data);
            setEntities(res.data);
          }
        });
    }
  }, []);

  const filteredEntities = entities.filter((entity) =>
    entity.entityName.toLowerCase().includes(filter.toLowerCase())
  );
  return (
    <Dialog
      open={open}
      onClose={() => {
        appStore.setShowEntityDialog(false);
      }}
      fullWidth
      maxWidth="md"
      style={{ width: "50%", margin: "0 auto" }}
    >
      <DialogTitle>
        <span>Select an Entity</span>
      </DialogTitle>
      <CloseButton
        onClick={() => {
          appStore.selectedEntity = entities[0].entityId;
          appStore.setShowEntityDialog(false);
          setOpen(false);
        }}
      >
        <CloseIcon />
      </CloseButton>
      <TextField
        sx={{ padding: "1px" }}
        variant="filled"
        placeholder="Filter entities..."
        onChange={(e) => setFilter(e.target.value)}
      />
      <DialogContent>
        {filteredEntities.map((entity) => (
          <EntityButton
            key={entity.entityId}
            variant="outlined"
            onClick={() => {
              appStore.setSelectedEntity(entity.entityId);
              appStore.setShowEntityDialog(false);
              setOpen(false);
            }}
            sx={{ minWidth: "200px", justifyContent: "left" }}
          >
            <IconButton>
              <Icon className="icon" sx={{ width: 36 }}>
                {"location_on"}
              </Icon>
            </IconButton>
            {entity.entityName}
          </EntityButton>
        ))}
      </DialogContent>
    </Dialog>
  );
});

export default EntityDialog;
